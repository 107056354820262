<template>
  <div class="row metric-cards">
    <!--<pre>-->
    <!--  {{activeCheckup}}-->
    <!--  {{activeCheckupEng}}-->
    <!--    <pre>{{normeringData}}</pre>-->

    <!--</pre>-->
    <div class="mb-4 col-6">
      <div class="h-100 card rounded-8 overflow-hidden">
        <div class="card-body text-center">
          <template v-if="checkups.length === 1">
            <!--            <pre v-bind:key="swarmcomputed" v-for="swarmcomputed in checkups">{{wbTeam(swarmcomputed)}}</pre>-->
            <progress-chart
              v-bind:key="swarmcomputed"
              v-for="swarmcomputed in checkups"
              class="mx-auto mb-2"
              :pct="
                scoretype === 'abs'
                  ? (wbTeam(swarmcomputed) / 7) * 100
                  : getNormering('wellbeing', wbTeam(swarmcomputed))
              "
              :f-size="'md'"
              border-width="6"
              :color="'groen-200'"
              style="width: 64px; height: 64px"
            ></progress-chart>
          </template>
          <template v-else>
            <progress-chart
              class="mx-auto mb-2"
              :pct="
                scoretype === 'abs'
                  ? (wbTeam(activeCheckup) / 7) * 100
                  : getNormering('wellbeing', wbTeam(activeCheckup))
              "
              :f-size="'md'"
              border-width="6"
              :color="'groen-200'"
              style="width: 64px; height: 64px"
            ></progress-chart>
            <!--<nav class="mt-3 d-flex justify-content-center">
              <span
                class=""
                v-bind:key="checkup"
                v-for="(checkup, i) in checkups"
              >
                <span
                  class="
                    rounded-circle
                    mx-1
                    d-block
                    c-200
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                  :class="'bgc-' + checkup.color"
                  style="height: 12px; width: 12px; cursor: pointer"
                  @click="activeCheckupKey = i"
                >
                  <span
                    style="height: 8px; width: 8px; pointer-events: none"
                    v-if="activeCheckupKey !== i"
                    class="bg-white rounded-circle d-block"
                  ></span>
                </span>
              </span>
            </nav>-->
          </template>
          <div class="title overline mt-3 mb-2">
            <span class="text-sentence">{{ $t("message.WB") }}
            {{ activeCheckup ? activeCheckup.title : "" }}</span>
          </div>
        </div>
      </div>
    </div>
<!--    <div v-show="scoretype === 'abs'" class="mb-4 col-6 col-md-3">
      <div class="h-100 card rounded-8 overflow-hidden">
        <div class="card-body text-center">
          &lt;!&ndash;          <pre >{{companyWB}}</pre>&ndash;&gt;

          <progress-chart
            class="mx-auto mb-2"
            :pct="
              scoretype === 'abs'
                ? (companyWB / 7) * 100
                : getNormering('wellbeing', companyWB)
            "
            :f-size="'md'"
            :color="'groen-200'"
            border-width="6"
            style="width: 64px; height: 64px"
          ></progress-chart>
          <div class="title overline mt-3 mb-2">
            <span class="text-sentence">{{ $t("message.WB") }} {{ $t("message.company") }}</span>
          </div>
        </div>
      </div>
    </div>-->
    <div class="mb-4 col-6">
      <div class="h-100 card rounded-8 overflow-hidden">
        <div class="card-body text-center">
          <template v-if="checkups.length === 1">
            <progress-chart
              v-bind:key="checkup"
              v-for="checkup in checkups"
              class="mx-auto mb-2"
              :pct="
                scoretype === 'abs'
                  ? (engTeam(checkup) / 7) * 100
                  : getNormering('engagement', engTeam(checkup))
              "
              :color="'blauw-50'"
              :f-size="'md'"
              border-width="6"
              style="width: 64px; height: 64px"
            ></progress-chart>
          </template>
          <template v-else>
            <progress-chart
              class="mx-auto mb-2"
              :pct="
                scoretype === 'abs'
                  ? (engTeam(activeCheckupEng) / 7) * 100
                  : getNormering('engagement', engTeam(activeCheckupEng))
              "
              :f-size="'md'"
              border-width="6"
              :color="'blauw-50'"
              style="width: 64px; height: 64px"
            ></progress-chart>
            <!--<nav class="mt-3 d-flex justify-content-center">
              <span
                class=""
                v-bind:key="checkup"
                v-for="(checkup, i) in checkups"
              >
                <span
                  class="
                    rounded-circle
                    mx-1
                    d-block
                    c-200
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                  :class="checkup.color ? 'bgc-' + checkup.color : 'groen'"
                  style="height: 12px; width: 12px; cursor: pointer"
                  @click="activeCheckupEngKey = i"
                >
                  <span
                    style="height: 8px; width: 8px; pointer-events: none"
                    v-if="activeCheckupEngKey !== i"
                    class="bg-white rounded-circle d-block"
                  ></span>
                </span>
              </span>
            </nav>-->
          </template>
          <div class="title overline mt-3 mb-2">
            <span class="text-sentence">{{ $t("message.ENG") }}
            {{ activeCheckupEng ? activeCheckupEng.title : "" }}</span>
          </div>
        </div>
      </div>
    </div>
<!--    <div v-show="scoretype === 'abs'" class="mb-4 col-6 col-md-3">
      <div class="h-100 card rounded-8 overflow-hidden">
        <div class="card-body text-center">
          <progress-chart
            class="mx-auto mb-2"
            :pct="
              scoretype === 'abs'
                ? (companyEng / 7) * 100
                : getNormering('engagement', companyEng)
            "
            :f-size="'md'"
            :color="'blauw-50'"
            border-width="6"
            style="width: 64px; height: 64px"
          ></progress-chart>
          <div class="title overline mt-3 mb-2">
            <span class="text-sentence">{{ $t("message.ENG") }} {{ $t("message.company") }}</span>
          </div>
        </div>
      </div>
    </div>-->
    <div class="row align-items-center justify-content-end">
      <div class="mb-3 col-md-auto col-12" v-if="checkups.length > 1">
        <select name="" id="" class="w-100 input-lg" v-model="activeCheckupKey">
          <option v-for="(checkup, i) in checkups" :value="parseInt(i)" :key="checkup.id">{{ checkup.title }}</option>
        </select>
      </div>
      <div class="mb-3 col-md-auto col-12">
        <select
          @change="handleBarValues"
          name=""
          id=""
          class="w-100 input-lg home-toggle-scores"
        >
          <option value="average">{{ $t("message.average") }}</option>
          <option value="minMax">{{ $t("message.min_max") }}</option>
        </select>
      </div>
    </div>
    <div class="col-12 mt-3">
      <bar-chart
        :hide-line="true"
        :hidelegend="true"
        :labels="performanceLabelsLocalized"
        :datasets="dataSets"
      ></bar-chart>
      <div class="overflow-auto">
        <table class="my-4 text-center table table-bordered table-responsive">
        <thead class="">
          <tr>
            <th class="fw-normal text-capitalize">
              {{ $t("message.standard_deviation") }}
            </th>
            <th
              :style="
                'background-color:' +
                this.setOpacity(
                  this.hexToRgb(
                    this.getColorCode(this.getColorByVitamin(label))
                  ),
                  0.3
                )
              "
              class="fw-normal text-capitalize text-sm"
              v-for="label in performanceLabels"
              v-bind:key="label"
            >
              {{ $t("message." + label) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="sw in checkups" v-bind:key="sw">
            <td class="text-center text-sm">
              {{ sw.title }}<span v-if="sw.checkupentry"> -
              {{ moment(sw.checkupentry.end).format("DD/MM/YYYY") }}</span>
            </td>
            <td v-bind:key="i" v-for="(data, i) in getData(sw, 'sd')">
              {{ Math.round(data * 100) / 100 }}%
            </td>
          </tr>
        </tbody>
        </table>
      </div>
      <line-chart
        v-if="lineLabels.length > 1 && sameTeams"
        :labels="lineLabels"
        :datasets="lineDatasets"
      ></line-chart>
    </div>
  </div>
</template>
<script>
import ProgressChart from "@/views/components/ProgressChart";
import LineChart from "@/views/components/charts/LineChart";
import BarChart from "@/views/components/charts/BarChart";
import moment from "moment";
import _ from 'lodash';

export default {
  mounted() {
    // this.getCompanyStats();
    this.checkups = this.computeds.map((checkup) => {
      !checkup.checkup ? (checkup.checkup = { color: "koraalrood" }) : false;
      return checkup;
    });
  },
  watch: {
    computeds() {
      this.activeCheckupKey  = 0;
      this.checkups = this.computeds.map((checkup) => {
        !checkup.checkup ? (checkup.checkup = { color: "koraalrood" }) : false;
        return checkup;
      });
    },
  },
  components: {
    ProgressChart,
    LineChart,
    BarChart,
  },
  props: {
    computeds: {
      default() {
        return [];
      },
      type: Array,
    },
  },
  computed: {
    activeCheckup() {
      return this.checkups ? this.checkups[this.activeCheckupKey] : 0;
    },
    activeCheckupEng() {
      return this.checkups ? this.checkups[this.activeCheckupKey] : 0;
    },
    sameTeams() {
      let t = true;
      let id = this.checkups[0].swarm;
      this.checkups.forEach((ch) => {
        if (ch.swarm !== id) {
          t = false;
        }
        if (!t) {
          return t;
        }
      });

      return t;
    },
    performanceLabelsLocalized() {
      return this.performanceLabels.map((label) => {
        return this.$t("message." + label);
      });
    },
    lineDatasets() {
      const engagement = [];
      const wellbeing = [];
      let teamid = null;
      _.orderBy(this.checkups, 'checkupentry.end').forEach((swarmcomputed) => {
        if (swarmcomputed && swarmcomputed.stats) {
          if (teamid === null) {
            teamid = swarmcomputed.stats.teamid;
          }
          if (teamid === swarmcomputed.stats.teamid) {
            engagement.push((swarmcomputed.stats.data["Eng_mean"] / 7) * 100);
            wellbeing.push((swarmcomputed.stats.data["WB_mean"] / 7) * 100);
          }
        } else {
          engagement.push(null);
          wellbeing.push(null);
        }
      });
      return [
        {
          label: this.$t("message.Eng"),
          borderColor: this.getColorCode("okergeel-200"),
          backgroundColor: this.getColorCode("okergeel-200"),
          data: engagement,
        },
        {
          label: this.$t("message.WB"),
          borderColor: this.getColorCode("groengrijs-200"),
          backgroundColor: this.getColorCode("groengrijs-200"),
          data: wellbeing,
        },
      ];
    },
    lineLabels() {
      let teamid = null;
      const labels = [];
      _.orderBy(this.checkups, 'checkupentry.end').forEach((swarmcomputed) => {
        if (swarmcomputed && swarmcomputed.stats) {
          if (teamid === null) {
            teamid = swarmcomputed.swarm.id;
          }
          if (teamid === swarmcomputed.swarm.id) {
            labels.push(
                (swarmcomputed.checkupentry ? moment(swarmcomputed.checkupentry.end).format("YYYY-MM-DD") +
                " - " : '') +
                swarmcomputed.title
            );
          }
        }
      });

      // const labels = this.checkups.map(checkup => {
      //   return moment(checkup.end).format('YYYY-MM-DD') + ' - ' + checkup.checkup.title
      // })
      return labels;
    },
    checkupIds() {
      return this.checkups.map((checkup) => {
        return checkup.id;
      });
    },
    dataSets() {
      // let _this = this;
      const sets = [];
      if (this.checkups.length > 0) {
        // console.log(this.checkups);
        this.checkups.forEach((swarmcomputed, i) => {
          const isActive = this.checkups.length > 1 && i === parseInt(this.activeCheckupKey);
          // console.log(swarmcomputed);
          if (this.barChartType == "minMax") {
            sets.push({
              barPercentage: 1.0,
              categoryPercentage: 1.0,
              type: "bar",
              label:
                this.scoretype === "abs"
                  ? swarmcomputed.title + " " + this.$t("message.min_max")
                  : swarmcomputed.title,
              borderWidth: isActive ? 2 : 1,
              // backgroundColor: this.setOpacity(this.hexToRgb(this.getColorCode(swarmcomputed.checkup.color + '-200')), 0.3),
              backgroundColor: this.performanceLabels.map((label) => {
                let opacity = 0.5;
                if (isActive) opacity = 0.8;
                if (label == "WB" || label == "Eng") {
                  opacity = 1;
                }
                return this.setOpacity(
                  this.hexToRgb(
                      isActive ? this.adjustColor(this.getColorCode(this.getColorByVitamin(label)), 20) :
                    this.getColorCode(this.getColorByVitamin(label))
                  ),
                  opacity
                );
              }),
              borderColor: this.performanceLabels.map((label) => {
                return this.setOpacity(
                  this.hexToRgb(
                      isActive ? this.adjustColor(this.getColorCode(this.getColorByVitamin(label)), -20) :
                          this.getColorCode(this.getColorByVitamin(label))
                  ),
                  0.9
                );
              }),

              data: this.performanceLabels.map((label, ind) => {
                let score = null;
                if (this.scoretype === "abs") {
                  score = this.pairSets(
                    this.getData(swarmcomputed, "min"),
                    this.getData(swarmcomputed, "max")
                  )[ind];
                } else {
                  score = this.pairSets(
                    [0, 0, 0, 0, 0, 0],
                    this.getData(swarmcomputed, "mean")
                  )[ind];

                  // score = this.getData(swarmcomputed, 'mean')
                }
                return {
                  x: ind,
                  y: score,
                };
              }),

              // [
              // {x:0,y:[2,3]},
              // {x:1,y:[2,3]},
              // {x:2,y:[2,3]},
              // {x:3,y:[2,3]},
              // {x:4,y:[2,3]},
              // {x:5,y:[2,3]},
              // ]
              //     this.pairSets(
              //     this.getData(swarmcomputed, 'min'),
              //     this.getData(swarmcomputed, 'max'),
              // )
            });
          } else if (this.barChartType == "average") {
            /*if (this.scoretype === "abs") {*/
              sets.push({
                barPercentage: 1.0,
                categoryPercentage: 1.0,
                type: "bar",
                label: swarmcomputed.title + " " + this.$t("message.mean"),
                borderWidth: isActive ? 2 : 1,
                // backgroundColor: this.setOpacity(this.hexToRgb(this.getColorCode(swarmcomputed.checkup.color + '-200')), 0.3),
                backgroundColor: this.performanceLabels.map((label) => {
                  let opacity = 0.5;
                  if (isActive) opacity = 0.8;
                  if (label == "WB" || label == "Eng") {
                    opacity = 1;
                  }
                  return this.setOpacity(
                    this.hexToRgb(
                        isActive ? this.adjustColor(this.getColorCode(this.getColorByVitamin(label)), 20) :
                            this.getColorCode(this.getColorByVitamin(label))
                    ),
                    opacity
                  );
                }),
                borderColor: this.performanceLabels.map((label) => {
                  return this.setOpacity(
                    this.hexToRgb(
                        isActive ? this.adjustColor(this.getColorCode(this.getColorByVitamin(label)), -20) :
                            this.getColorCode(this.getColorByVitamin(label))
                    ),
                    0.9
                  );
                }),

                data: this.performanceLabels.map((label, ind) => {
                  // console.log(label);
                  // console.log(ind);
                  let score = null;
                  // let score = null;
                  // if (this.scoretype === "abs") {
                  score = this.getData(swarmcomputed, "mean")[ind];
                  // console.log(score);
                  // console.log(ind);
                  return {
                    x: ind,
                    y: Math.round(score),
                  };
                  // } else {
                  //   score = this.pairSets(
                  //     [0, 0, 0, 0, 0, 0],
                  //     this.getData(swarmcomputed, "mean")
                  //   )[ind];

                  //   // score = this.getData(swarmcomputed, 'mean')
                  // }
                  // return {
                  //   x: ind,
                  //   y: score,
                  // };
                }),

                // data: _this.meanData(swarmcomputed, ind),
              });
            /*}*/
          }
        });
      }
      return sets;
    },
  },
  created() {
    this.getNormeringData();
  },
  data() {
    return {
      normeringData: null,
      scoretype: "abs",
      moment: moment,
      checkups: [],
      companyEng: 0,
      companyWB: 0,
      activeCheckupKey: 0,
      // activeCheckup: this.checkups ? this.checkups[0] : null,
      activeCheckupEngKey: 0,
      types: [
        "wellbeing",
        "engagement",
        "energiebronnen",
        "hinderpalen",
        "hulpbronnen",
        "uitdagingen",
      ],
      performanceLabels: ["WB", "Eng", "EB", "HIN", "PHB", "UIT"],
      barChartType: "average",
    };
  },
  methods: {
    handleBarValues(e) {
      this.barChartType = e.target.value;
    },
    async getNormeringData() {
      const get = await this.axios.get(
        "/analytics-items?isactive=true&type=normeringTeams&_sort=subtype:asc"
      );
      this.normeringData = get.data;
    },
    getNormering(type, val) {
      const n = this.normeringData.find((norm) => {
        return norm.subtype.toLowerCase() === type;
      });
      // console.log(val, n.data[val], n.data);
      if (n && n.data[val]) {
        return n.data[val];
      } else {
        return 0;
      }
    },
    meanData(sw, set_ind) {
      // console.log(x_ind)
      // let offset = ind;
      let setLength = this.checkups.length;
      let x = null;
      if (setLength > 1) {
        // let prev = false
        x = this.performanceLabels.map((label, x_ind) => {
          // console.log([label, set_ind])
          let x = null;
          let stepSize = 1;
          let prevX = x_ind - stepSize;
          let startX = prevX + stepSize / 2; //find start of column's X-value

          let center = stepSize / setLength;
          x = startX + center * set_ind + center / 2;

          // xInd - vorigeXInd bvb 2-1 = 1 = stepSize
          // vorigeXInd + stepSize/2 = 1.5 = startPunt
          // stepSize (1) / aantal datasets (2) = 0.5
          // 0.5/2 = center

          // startPunt + (center*setIndex)

          // return ((1/setLength)/2)*x_ind
          // return (x_ind - ((set_ind/setLength)))+(((1/setLength)/setLength)*setLength)
          return x;
          // return x_ind
        });
      } else {
        x = this.performanceLabels.map((label, set_ind) => {
          return set_ind;
        });
      }

      // console.log('x axis values', x)

      const d = this.pairSets(
        // [0,0,0,0,0,0],
        x,
        // [0, 1, 2, 3, 4, 5],
        // this.performanceLabelsLocalized,

        this.getData(sw, "mean")
      );
      // console.log('d', d)
      return d;
    },
    async getCompanyStats() {
      const get = await this.axios.get(
        "/analytics-items?type=descriptives&subtype=ew&_sort=updated_at:DESC&_limit=1"
      );
      const stats = get.data[0];
      let meanInd = 0;
      // console.log('get', get)
      // console.log('stats', stats)
      stats.data.names.forEach((name, ind) => {
        if (name === "mean") {
          meanInd = ind;
        }
      });
      this.companyEng = stats.data.ENG[meanInd];
      this.companyWB = stats.data.WB[meanInd];
    },
    wbTeam(swarmcomputed) {
      let wb = 0;
      let found = false;
      // this.checkups.forEach(checkup => {
      if (swarmcomputed && swarmcomputed.stats && found === false) {
        wb = swarmcomputed.stats.data["WB_mean"];
        found = true;
      }
      // })
      return wb;
    },
    engTeam(swarmcomputed) {
      let eng = 0;
      let found = false;
      // this.checkups.forEach(checkup => {
      if (swarmcomputed && swarmcomputed.stats && found === false) {
        eng = swarmcomputed.stats.data["Eng_mean"];
        found = true;
      }
      // })
      return eng;
    },
    pairSets(set1, set2) {
      const ret = [];
      set1.forEach((v, i) => {
        ret.push([Math.round(set1[i]), Math.round(set2[i])]);
      });
      return ret;
    },
    setScoreType(type) {
      this.scoretype = type;
    },
    getData(swarmcomputed, type) {
      const ret = [];
      if (swarmcomputed && swarmcomputed.stats) {
        const data = swarmcomputed.stats.data;

        this.performanceLabels.forEach((label, ind) => {
          let key = label + "_" + type;
          // console.log('key', [key, data[key]])
          const d =
            this.scoretype === "abs" || type === "sd"
              ? (data[key] / 7) * 100
              : this.getNormering(this.types[ind], data[key]);
          ret.push(d);
          // ret.push(data[key] / 7 * 100)
        });
      }
      return ret;
      //todo filter stats that match chosen filter
    },
  },
};
</script>

<style>
</style>