<template>
  <div class="page-checkups pb-4 mb-4">
    <!--    <div class="row top-row ">-->
    <!--      <div class="col-auto  tree-filters">-->
    <!--        <div class="row align-items-center">-->
    <!--          <div class="mb-2 col-md-auto">-->
    <!--            <search size="lg" @search="handleSearch"></search>-->
    <!--          </div>-->
    <!--          <div class="mb-2 col">-->
    <!--            <select v-if="user.swarms.length > 0 && user.managecompany" @change.prevent="handleSwitch"-->
    <!--                    v-model="checkupType" class="input-lg w-100" name="" id="">-->
    <!--              <option value="own">Manager</option>-->
    <!--              <option value="all">Company</option>-->
    <!--            </select>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="mb-3 ms-auto col-md-auto col tree-filters">-->
    <!--        <div class="row align-items-center">-->
    <!--          <div class="col-md-auto col-12">-->
    <!--            <select @change="handleScoreType" name="" id="" class="w-100 input-lg home-toggle-scores">-->
    <!--              <option value="abs">Absolute scores</option>-->
    <!--              <option value="pctl">Percentielen</option>-->
    <!--            </select>-->
    <!--          </div>-->
    <!--          <div class="d-none ml-auto col-auto">-->
    <!--            <input type="date" class="input-lg" @change="getCheckups()" v-model="date">-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="row schedule-row">
      <div class="col-xl-5 col-lg-12 mb-3">
        <div class="row align-items-center">
          <div class="mb-3 col-md-auto">
            <search size="lg" @search="handleSearch"></search>
          </div>
          <div class="mb-3 col">
            <!-- <select
              v-if="user.swarms && user.swarms.length > 0 && user.managecompany"
              @change.prevent="handleSwitch"
              v-model="checkupType"
              class="input-lg w-100"
              name=""
              id=""
            >
              <option value="own">Manager</option>
              <option value="all">Company</option>
            </select> -->
            <div
              v-if="user.managecompany"
              class="responsive-btn-group btn-group w-100"
              role="group"
              aria-label="Basic example"
            >
              <button
                :class="list_type === 'own' ? 'active' : ''"
                @click="handleCheckups('own')"
                type="button"
                class="btn-sm btn btn-secondary"
              >
                <span class="text-sentence">{{ $t("message.manager") }}</span>
              </button>
              <button
                :class="list_type === 'all' ? 'active' : ''"
                @click="handleCheckups('all')"
                type="button"
                class="btn-sm btn btn-secondary"
              >
                <span class="text-sentence">{{ $t("message.company") }}</span>
              </button>
            </div>
          </div>
        </div>

        <div class="checkups-page-cards checkup-card card">
          <div class="card-header bgc-groengrijs c-200 text-white">
            <span class="overline text-sm ">{{
              $t("message.checkups")
            }}</span>
          </div>
          <div class="card-body">
            <paginated
              v-if="checkupsLoaded"
              style="min-height: 150px"
              @change="handlePagination"
              :defer-loading="true"
              :loaded="contentReady"
              :request="request"
              :total-rows="totalCount"
              :per-page="10"
            >
              <div
                :class="index < checkups.length - 1 && 'mb-4'"
                :style="
                  checkup.isOpen
                    ? 'box-shadow: inset 1px 3px 10px #0002!important;border:1px solid #0001'
                    : ''
                "
                class="card rounded-8"
                v-for="(checkup, index) in checkups"
                :key="checkup"
              >
                <div
                  class="card-header c-50 pe-2"
                  :class="'bgc-' + checkup.checkup.color"
                >
                  <div class="overline text-sm">
                    <span
                      >{{ $t("message.ended_on") }} {{ checkup.end }}</span
                    >
                  </div>
                </div>
                <div class="card-body">
                  <div
                    @click.stop.prevent="checkup.isOpen = !checkup.isOpen"
                    class="row"
                    :class="checkup.isOpen && 'mb-3'"
                  >
                    <div class="col">
                      <h5>{{ checkup.checkup.title }}</h5>
                      <p>
                        <img
                          src="../assets/icons/Icon-sidebar-tree.svg"
                          alt=""
                          class="me-1"
                        />
                        <span class="text-sentence"
                          >{{ checkup.swarmcomputeds.length }}
                          {{ $t("message.teams") }} /
                          {{
                            checkup.swarmcomputeds.filter((entity) => entity.included)
                              .length
                          }}
                          {{ $t("message.selected") }}</span
                        >
                      </p>
                    </div>
                    <div class="col-auto text-end">
                      <span v-show="checkup.isOpen"
                        ><i class="fas fa-chevron-down"></i
                      ></span>
                      <span v-show="!checkup.isOpen"
                        ><i class="fas fa-chevron-right"></i
                      ></span>
                    </div>
                  </div>
                  <input
                    v-model="checkup.search"
                    v-show="checkup.swarmcomputeds.length > 1 && checkup.isOpen"
                    type="text"
                    name=""
                    id=""
                    class="input input-lg mb-3 w-100 ms-auto"
                    :placeholder="$t('message.search')"
                  />
                  <div class="checkup-subgroup" v-show="checkup.isOpen">
                    <div
                      :class="computed.included && 'bgc-theme c-200 rounded-8'"
                      class="row mx-0 py-3 align-items-center mb-2"
                      v-for="computed in filteredChildren(checkup)"
                      :key="computed.id"
                    >
                      <div class="col-auto">
                        <checkbox v-model="computed.included" />
                      </div>
                      <div class="col-auto">
                        <progress-chart
                            v-if="checkup.isOpen"
                          style="height: 38px; width: 38px"
                          class=""
                          :border-width="2"
                          :f-size="'sm'"
                          :color="checkup.color"
                          :pct="
                            (computed.users.participation /
                              computed.users.users.length) *
                            100
                          "
                        ></progress-chart>
                      </div>
                      <div class="col">
                        <h5 class="mb-0">{{ computed.title }}</h5>
                      </div>
                      <div class="col">
                        <div
                          class="text-sm mb-2"
                          v-for="swarm in computed.swarms"
                          :key="swarm.id"
                        >
                          <img
                            v-if="swarm.type == 'team'"
                            class="me-1"
                            src="../assets/icons/Icon-sidebar-tree.svg"
                            alt=""
                          />
                          <img
                            v-else
                            class="me-1"
                            src="../assets/icons/Icon-sidebar-segments.svg"
                            alt=""
                          />
                          {{ swarm.title }}
                        </div>
                        <div class="text-sm">
                          <span class="me-3">
                            <img
                              class="me-1"
                              src="../assets/icons/Icon-sidebar-Users.svg"
                              alt=""
                            />
                            &nbsp;{{ computed.users.users.length ?? "0" }}</span
                          >
                          <span>
                            <img
                              class="me-1"
                              src="../assets/icons/Icon-tree-manager-profile.svg"
                              alt=""
                            />
                            &nbsp;{{
                              computed.users.managers.length ?? "0"
                            }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--              <div v-if="checkups.length < 1">-->
              <!--                No checkups available.-->
              <!--              </div>-->

              <!--              <div :class="index < (checkups.length-1) ? 'mb-4' : ''" class="card rounded-8"
                                 :style="activeCheckup == checkup ? 'box-shadow: inset 1px 3px 10px #0002!important;border:1px solid #0001' : ''"
                                 @click.stop="setActiveCheckup(checkup)"
                                 v-for="(checkup,index) in checkups" v-bind:key="checkup">

                              &lt;!&ndash;                                <pre>{{checkup}}</pre>&ndash;&gt;
                              <div class="card-header c-50 pe-2"
                                   :class="'bgc-'+(checkup.checkupentry ? checkup.checkupentry.checkup.color : '')">
              &lt;!&ndash;                  <div class="overline text-sm">Ended on {{ moment(checkup.checkupentry.enddate).format('DD/MM/YYYY') }}</div>&ndash;&gt;
                                <div class="overline text-sm">Ended on {{ checkup.checkupentry ? checkup.checkupentry.checkup.title + ' ' + moment(checkup.checkupentry.end).format('DD/MM/YYYY') : ''}}</div>-->

              <!--                  <div class="ms-auto nav">-->
              <!--                    <ul class="nav nav-pills p-0">-->
              <!--                      <li class="nav-item dropdown p-0">-->
              <!--                        <a class="nav-link p-0" data-bs-toggle="dropdown" href="#" role="button"-->
              <!--                           aria-expanded="false">-->
              <!--                          <img src="../assets/icons/Icon-navigation-More-vertical.svg" alt="">-->
              <!--                        </a>-->
              <!--                        <ul class="dropdown-menu">-->
              <!--                          <li><a class="dropdown-item" href="#">Action</a></li>-->
              <!--                          <li><a class="dropdown-item" href="#">Another action</a></li>-->
              <!--                          <li><a class="dropdown-item" href="#">Something else here</a></li>-->
              <!--                          <li>-->
              <!--                            <hr class="dropdown-divider">-->
              <!--                          </li>-->
              <!--                          <li><a class="dropdown-item" href="#">Separated link</a></li>-->
              <!--                        </ul>-->
              <!--                      </li>-->
              <!--                    </ul>-->

              <!--                  </div>-->

              <!--           PREVIOUS CARDS       -->
              <!--</div>
              <div class="card-body">
                <div class="row">
                  <div class="col-auto">
                    <progress-chart style="height: 38px;width: 38px;"
                                    class=""
                                    :border-width="2"
                                    :f-size="'sm'"
                                    :color="checkup.color"
                                    :pct="(checkup.users.participation / checkup.users.users.length)*100"></progress-chart>
                  </div>
                  <div class="col">
                    <h5>{{ checkup.title }}</h5>
                    <div class="text-sm mb-2" v-for="swarm in checkup.swarms" :key="swarm.id">
                      <img v-if="swarm.type == 'team'" class="me-1" src="../assets/icons/Icon-sidebar-tree.svg"
                           alt="">
                      <img v-else class="me-1" src="../assets/icons/Icon-sidebar-segments.svg" alt="">
                      {{ swarm.title }}
                    </div>
                    <div class="text-sm">
                      <span class="me-3">
                        <img class="me-1" src="../assets/icons/Icon-sidebar-Users.svg" alt="">
                        &nbsp;{{ checkup.users.users.length ?? '0' }}</span>
                      <span>
                        <img class="me-1" src="../assets/icons/Icon-tree-manager-profile.svg" alt="">
                        &nbsp;{{ checkup.users.managers.length ?? '0' }}</span>
                    </div>
                  </div>
                </div>
              </div>
&lt;!&ndash;                                <pre>&ndash;&gt;
&lt;!&ndash;                              {{ checkup }}&ndash;&gt;
&lt;!&ndash;                            </pre>&ndash;&gt;
            </div>-->
            </paginated>
          </div>
        </div>
      </div>
      <div class="col-xl-7 col-lg-12 mb-3" v-if="activeCheckups.length">
        <div class="row align-items-center justify-content-end">
          <div class="mb-3 col-md-auto col-12">
            <select
              @change="handleScoreType"
              name=""
              id=""
              class="w-100 input-lg home-toggle-scores"
            >
              <option value="abs">
                <span class="text-sentence">{{
                  $t("message.absoluteScores")
                }}</span>
              </option>
              <option value="pctl">Percentielen</option>
            </select>
          </div>
          <div class="mb-3 d-none ml-auto col-auto">
            <input
              type="date"
              class="input-lg"
              @change="getCheckups()"
              v-model="date"
            />
          </div>
        </div>

        <div class="card h-100">
          <div class="card-header bgc-groengrijs c-50">
            <span class="overline text-sm ">{{
              $t("message.checkup_analytics")
            }}</span>
          </div>
          <div class="card-body">
            <div class="action-filters mb-4 row justify-content-between">
              <!--              <div class="col">-->
              <!--                <h4 class="mb-0">{{ activeCheckup ? activeCheckup.title ?? 'Checkup A' : 'No checkup loaded' }}</h4>-->
              <!--              </div>-->
              <div
                v-if="activeCheckups.length < 2"
                class="ms-auto col-auto row"
              >
                <div class="col-auto">
                  <button
                    @click="scrollToForm('messages')"
                    type="button"
                    class="btn-sm btn btn-secondary active"
                  >
                    <img
                      class="hide-dark"
                      src="../assets/icons/icon-navigation-add.svg"
                      alt=""
                    />
                    <img
                      class="hide-light"
                      src="../assets/icons/icon-navigation-add-light.svg"
                      alt=""
                    />
                    Message
                  </button>
                </div>
                <div class="col-auto">
                  <button
                    @click="/*openActionModal(null)*/ scrollToForm('actions')"
                    type="button"
                    class="btn-sm btn btn-secondary"
                  >
                    <img
                      class="hide-dark"
                      src="../assets/icons/icon-navigation-add.svg"
                      alt=""
                    />
                    <img
                      class="hide-light"
                      src="../assets/icons/icon-navigation-add-light.svg"
                      alt=""
                    />
                    {{ $t("message.action") }}
                  </button>
                </div>
              </div>
            </div>
            <div class="content">
              <checkup-analytics
                ref="analytics"
                :computeds="activeCheckups"
              ></checkup-analytics>

              <!--  checkup must be passed as array, component can also fetch analytics for multiple checkups  -->
              <div v-if="activeCheckups.length < 2">
                <div class="mb-3 border-top pt-3 mt-4" id="formSelector">
                  <select v-model="shownForm" name="" id="">
                    <option value="actions">
                      {{ $t("message.teamActions") }}
                    </option>
                    <option value="messages">
                      {{ $t("message.teamMessages") }}
                    </option>
                    <option
                      v-if="user.managecompany && checkupType == 'all'"
                      value="c-actions"
                    >
                      {{ $t("message.companyActions") }}
                    </option>
                    <option
                      v-if="user.managecompany && checkupType == 'all'"
                      value="c-messages"
                    >
                      {{ $t("message.companyMessages") }}
                    </option>
                  </select>
                </div>
                <div class="actions" v-if="shownForm === 'actions'">
                  <action-form
                    type="company"
                    defer="true"
                    @submit-action-form="submitActionForm"
                  ></action-form>
                  <div class="mt-3">
                    <paginated
                      @change="handleActionPagination"
                      :request="actionRequest"
                      :total-rows="actionCount"
                      :per-page="10"
                    >
                      <div
                        class="rounded-8 action-item bgc-theme c-200 p-3 mb-2"
                        v-bind:key="action"
                        v-for="action in actions"
                      >
                        <div class="row">
                          <div class="col-auto flex-shrink-0">
                            <checkbox
                              @change="updateAction($event, action.id)"
                              v-model="action.completed"
                            ></checkbox>
                          </div>
                          <div class="col">
                            {{ action.body }}
                          </div>
                          <div class="col-auto flex-shrink-0">
                            <ul class="nav nav-pills p-0">
                              <li class="nav-item dropdown p-0">
                                <a
                                  class="nav-link p-0"
                                  data-bs-toggle="dropdown"
                                  href="#"
                                  role="button"
                                  aria-expanded="false"
                                >
                                  <img
                                    src="../assets/icons/Icon-navigation-More-vertical.svg"
                                    alt=""
                                  />
                                </a>
                                <ul class="dropdown-menu">
                                  <li>
                                    <a
                                      @click.prevent="
                                        openActionModal(action.id)
                                      "
                                      class="dropdown-item text-sentence"
                                      href="#"
                                      >{{ $t("message.edit") }}</a
                                    >
                                  </li>
                                  <li>
                                    <a
                                      @click.prevent="
                                        handleDeleteAction(action)
                                      "
                                      class="dropdown-item text-sentence"
                                      href="#"
                                      >{{ $t("message.delete") }}</a
                                    >
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </paginated>
                  </div>
                </div>
                <div class="messages" v-if="shownForm === 'messages'">
                  <message-form
                    type="team"
                    :current-team="actionTeam"
                    @submit-message-form="handleModalSubmit"
                  />
                  <paginated
                    @change="handleMessagePagination"
                    :request="messageRequest"
                    :total-rows="messageCount"
                    :per-page="10"
                  >
                    <div
                      v-bind:key="message"
                      v-for="message in messages"
                      class="message mb-3 border rounded-16 p-3 bc-blauw c-200"
                    >
                      <div
                        class="
                          mb-4
                          message-header
                          row
                          justify-content-between
                          align-items-center
                        "
                      >
                        <div class="col-auto">
                          <div class="c-blauw c-200 text-sm">
                            <span class="text-sentence"
                              >{{ message.created_at }} &bullet;
                              {{ $t("message.writtenBy") }}
                              {{ message.author.firstname }}
                              {{ message.author.lastname }}</span
                            >
                          </div>
                        </div>
                        <div class="col-auto nav">
                          <ul class="nav nav-pills p-0">
                            <li class="nav-item dropdown p-0">
                              <a
                                class="nav-link p-0"
                                data-bs-toggle="dropdown"
                                href="#"
                                role="button"
                                aria-expanded="false"
                              >
                                <img
                                  src="../assets/icons/Icon-navigation-More-vertical.svg"
                                  alt=""
                                />
                              </a>
                              <ul class="dropdown-menu">
                                <li>
                                  <button
                                    class="dropdown-item"
                                    @click="handleEditMessage(message)"
                                  >
                                    <span class="text-sentence">{{
                                      $t("message.editMessage")
                                    }}</span>
                                  </button>
                                </li>
                                <li>
                                  <button
                                    class="dropdown-item"
                                    @click="handleDeleteMessage(message)"
                                  >
                                    <span class="text-sentence">{{
                                      $t("message.deleteMessage")
                                    }}</span>
                                  </button>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                        <!--<div class="col-auto">
                        <span v-bind:key="tag" v-for="tag in message.tags"
                              class="badge bgc-blauw c-50 rounded-8 fw-normal c-body me-2">{{ tag.title }}</span>
                        </div>-->
                      </div>
                      <div class="mb-3 message-body row">
                        <div class="col-12">
                          <h3>{{ message.title }}</h3>
                          <span v-html="message.body"></span>
                        </div>
                      </div>
                      <div class="message-footer row">
                        <div class="col-auto">
                          <span
                            v-bind:key="tag"
                            v-for="tag in message.tags"
                            class="
                              badge
                              bgc-blauw
                              c-50
                              rounded-8
                              fw-normal
                              c-body
                              me-2
                            "
                            >{{ tag.tagtext }}</span
                          >
                          <!--<button @click="showMessagePopup(message)" class="text-lg p-0 btn btn-link text-uppercase">Read
                            more
                          </button>-->
                        </div>
                      </div>
                    </div>
                  </paginated>
                </div>
                <div class="actions" v-if="shownForm === 'c-actions'">
                  <action-form
                    type="company"
                    defer="true"
                    @submit-action-form="submitActionForm"
                  ></action-form>
                  <div class="mt-3">
                    <paginated
                      @change="handleActionPagination"
                      :request="companyActionRequest"
                      :total-rows="companyActionCount"
                      :per-page="10"
                    >
                      <div
                        class="rounded-8 action-item bgc-theme c-200 p-3 mb-2"
                        v-bind:key="action"
                        v-for="action in actions"
                      >
                        <div class="row">
                          <div class="col-auto flex-shrink-0">
                            <checkbox
                              @change="updateAction($event, action.id)"
                              v-model="action.completed"
                            ></checkbox>
                          </div>
                          <div class="col">
                            {{ action.body }}
                          </div>
                          <div class="col-auto flex-shrink-0">
                            <ul class="nav nav-pills p-0">
                              <li class="nav-item dropdown p-0">
                                <a
                                  class="nav-link p-0"
                                  data-bs-toggle="dropdown"
                                  href="#"
                                  role="button"
                                  aria-expanded="false"
                                >
                                  <img
                                    src="../assets/icons/Icon-navigation-More-vertical.svg"
                                    alt=""
                                  />
                                </a>
                                <ul class="dropdown-menu">
                                  <li>
                                    <a
                                      @click.prevent="
                                        openActionModal(action.id)
                                      "
                                      class="dropdown-item text-sentence"
                                      href="#"
                                      >{{ $t("message.edit") }}</a
                                    >
                                  </li>
                                  <li>
                                    <a
                                      @click.prevent="
                                        handleDeleteAction(action)
                                      "
                                      class="dropdown-item text-sentence"
                                      href="#"
                                      >{{ $t("message.delete") }}</a
                                    >
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </paginated>
                  </div>
                </div>
                <div class="messages" v-if="shownForm === 'c-messages'">
                  <message-form
                    type="company"
                    :checkup="activeCheckups[0].checkupentry.id"
                    @submit-message-form="handleModalSubmit"
                  />
                  <paginated
                    @change="handleMessagePagination"
                    :request="companyMessageRequest"
                    :total-rows="companyMessageCount"
                    :per-page="10"
                  >
                    <div
                      v-bind:key="message"
                      v-for="message in messages"
                      class="
                        message
                        mb-3
                        border
                        rounded-16
                        p-3
                        bc-okergeel
                        c-200
                      "
                    >
                      <div
                        class="
                          mb-4
                          message-header
                          row
                          justify-content-between
                          align-items-center
                        "
                      >
                        <div class="col-auto">
                          <div class="c-okergeel c-200 text-sm">
                            <span class="text-sentence"
                              >{{ message.created_at }} &bullet;
                              {{ $t("message.writtenBy") }}
                              {{ message.author.firstname }}
                              {{ message.author.lastname }}</span
                            >
                          </div>
                          <!--                        <div class="c-blauw c-200"></div>-->
                        </div>
                        <div class="col-auto nav">
                          <ul class="nav nav-pills p-0">
                            <li class="nav-item dropdown p-0">
                              <a
                                class="nav-link p-0"
                                data-bs-toggle="dropdown"
                                href="#"
                                role="button"
                                aria-expanded="false"
                              >
                                <img
                                  src="../assets/icons/Icon-navigation-More-vertical.svg"
                                  alt=""
                                />
                              </a>
                              <ul class="dropdown-menu">
                                <li>
                                  <button
                                    class="dropdown-item"
                                    @click="handleEditMessage(message)"
                                  >
                                    <span class="text-sentence">{{ $t("message.editMessage") }}</span>
                                  </button>
                                </li>
                                <li>
                                  <button
                                    class="dropdown-item"
                                    @click="handleDeleteMessage(message)"
                                  >
                                    <span class="text-sentence">{{ $t("message.deleteMessage") }}</span>
                                  </button>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                        <!--<div class="col-auto">
                        <span v-bind:key="tag" v-for="tag in message.tags"
                              class="badge bgc-blauw c-50 rounded-8 fw-normal c-body me-2">{{ tag.title }}</span>
                        </div>-->
                      </div>
                      <div class="mb-3 message-body row">
                        <div class="col-12">
                          <h3>{{ message.title }}</h3>
                          <span v-html="message.body"></span>
                        </div>
                      </div>
                      <div class="message-footer row">
                        <div class="col-auto">
                          <span
                            v-bind:key="tag"
                            v-for="tag in message.tags"
                            class="
                              badge
                              bgc-okergeel
                              c-50
                              rounded-8
                              fw-normal
                              c-body
                              me-2
                            "
                            >{{ tag.tagtext }}</span
                          >
                          <!--<button @click="showMessagePopup(message)" class="text-lg p-0 btn btn-link text-uppercase">Read
                            more
                          </button>-->
                        </div>
                      </div>
                    </div>
                  </paginated>
                </div>
              </div>
              <!--              {{ activeCheckup }}-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProgressChart from "@/views/components/ProgressChart";
import Checkbox from "@/views/components/simple/Checkbox";
import Search from "@/views/components/simple/Search";
import ActionForm from "@/views/components/ActionForm";
// import ActionList from "@/views/components/ActionList";
import ActionModal from "@/views/components/modals/ActionModal";
import MessageModal from "@/views/components/modals/MessageModal";
import Paginated from "@/views/components/simple/Paginated";
import moment from "moment";
import CheckupAnalytics from "@/views/components/CheckupAnalytics";
import MessageForm from "@/views/components/MessageForm";
// import _ from "lodash";
import ConfirmDelete from "@/views/components/modals/ConfirmDelete";
import NewMessageModal from "@/views/components/modals/NewMessageModal";

export default {
  components: {
    MessageForm,
    CheckupAnalytics,
    ActionForm,
    Search,
    Checkbox,
    ProgressChart,
    Paginated,
  },
  /*  mounted() {

    },*/
  watch: {
    async activeCheckups(value) {
      if (value.length == 1) {
        await this.getActions(value[0].swarm);
        await this.getCompanyActions(value[0].checkupentry);
      }
    },
  },
  computed: {
    tagIDs() {
      return this.tags.map((entity) => entity.id);
    },
    tagQuery() {
      let params = [];
      for (const id of this.tagIDs) {
        params.push("id_nin=" + id);
      }

      return "/tags?" + params.join("&");
    },
    activeCheckups() {
      let active = [];
      for (const check of this.checkups) {
        active.push(...check.swarmcomputeds.filter((child) => child.included));
      }
      return active;
      // return this.activeList ? this.activeList.children.filter(child => child.included) : [];
    },
  },
  methods: {
    // hasActiveChild(checkup) {
    //   return checkup.children.find(child => child.id === this.activeCheckup.id);
    // },
    handleEditMessage(message) {
      this.$store.dispatch("openModal", {
        component: NewMessageModal,
        options: { centered: true },
        properties: {
          id: message.id,
          type: this.shownForm == "c-messages" ? "company" : "team",
        },
        events: {
          submitModal: this.getAllMessages,
        },
      });
    },
    async getAllMessages() {
      this.messageRequest = "";
      await this.getActions(this.activeCheckups[0].swarm, true);
      this.companyMessageRequest = "";
      await this.getCompanyActions(this.activeCheckups[0], true);
    },
    handleDeleteMessage(message) {
      this.$store.dispatch("openModal", {
        component: ConfirmDelete,
        options: { centered: true },
        properties: {
          title: this.$t('message.this_message'),
          id: message.id,
        },
        events: {
          submitModal: this.deleteMessage,
        },
      });
    },
    async deleteMessage(id) {
      await this.axios.delete("/messages/" + id);
      await this.getActions(this.activeCheckups[0].swarm, true);
      await this.getCompanyActions(this.activeCheckups[0], true);
    },
    filteredChildren(checkup) {
      if (checkup.search) {
        return checkup.swarmcomputeds.filter((child) =>
          child.title.toLowerCase().includes(checkup.search.toLowerCase())
        );
      }
      return checkup.swarmcomputeds;
    },
    checkupColor(checkup) {
      let c = "theme";
      if (checkup.checkup && checkup.checkup.color) {
        c = checkup.checkup.color;
      }
      return c;
    },
    scrollToForm(form) {
      this.shownForm = form;
      let el = document.querySelector("#formSelector");

      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    addTag(e) {
      this.tags.push(e);
    },
    removeTag(id) {
      const index = this.tagIDs.indexOf(id);
      this.tags.splice(index, 1);
    },
    async submitMessage(message) {
      await this.axios.post("/messages", message);
      await this.getActions(this.actionTeam);
      await this.getCompanyActions(this.activeCheckups[0].checkupentry);
    },
    async submitActionForm(data) {
      if (this.shownForm == "actions") {
        data.teamid = this.activeCheckups[0].swarm.toString();
      } else if (this.shownForm == "c-actions") {
        data.isbase = true;
      }
      await this.axios.post("/actions", data);
      await this.getActions(this.activeCheckups[0].swarm);
      await this.getCompanyActions(this.activeCheckups[0].checkupentry);
    },
    handleSearch(val) {
      this.search = val;
      this.getCheckups();
    },

    async handleSwitch() {
      this.checkups = [];
      this.contentReady = false;
      await this.getCheckups();
      this.checkupsLoaded = true;
    },
    async handleCheckups(val) {
      this.list_type = val;
      this.checkupType = val;
      this.checkups = [];
      this.contentReady = false;
      await this.getCheckups();
      this.checkupsLoaded = true;
    },

    async getEntriesForCheckups(checkups) {
      const filledCheckups = await Promise.all(
        await checkups.map(async (checkup) => {
          const get = await this.axios.get(
            "/checkupentries/" + checkup.checkupentry
          );
          const entry = get.data;
          checkup.checkupentry = entry;
          return checkup;
        })
      );
      return filledCheckups;
    },

    async handlePagination(data) {

      for (const checkup of data) {
        checkup.isOpen = false;
        const enddate = checkup.end;
        checkup.end = moment(checkup.end).format('DD/MM/YYYY');

        for (const computed of checkup.swarmcomputeds) {
          computed.included = false;
          computed.checkupentry = {
            id: computed.checkupentry,
            end: enddate,
          }
        }
      }

      this.checkups = data;

      if (this.checkups.length > 0) {
        this.checkups[0].isOpen = true;
        this.checkups[0].swarmcomputeds[0].included = true;
      }

      this.checkupsLoaded = true;
      this.contentReady = true;
    },
    handleActionPagination(data) {
      this.actions = data;
    },
    handleScoreType(e) {
      this.$refs.analytics.setScoreType(e.target.value);
    },
    handleMessagePagination(data) {
      for (const message of data) {
        message.created_at = moment(message.created_at).format("DD/MM/YYYY");
      }
      this.messages = data;
    },
    async handleDeleteAction(action) {
      this.$store.dispatch("openModal", {
        component: ConfirmDelete,
        options: { centered: true },
        properties: {
          title: action.body,
          id: action.id,
        },
        events: {
          submitModal: this.deleteAction,
        },
      });
    },
    async setActiveCheckup(checkup) {
      // console.log('get actions for ', checkup)
      await this.getActions(checkup.swarm);
      await this.getCompanyActions(checkup.checkupentry);
      this.activeCheckup = checkup;
    },
    async setActiveList(checkup) {
      for (const check of this.checkups) {
        if (check.isOpen) check.isOpen = false;

        for (const child of checkup.children) {
          child.color = checkup.color;
          child.included = false;
        }
      }
      this.activeList = checkup;
      checkup.isOpen = true;
      checkup.children[0].included = true;
    },
    async deleteAction(id) {
      await this.axios.delete("/actions/" + id);
      await this.getActions(this.activeCheckups[0].swarm);
      await this.getCompanyActions();
    },
    async getCheckups() {
      this.checkupsLoaded = false;
      // console.log('getting checkups')
      // console.log('Requesting checkups: ' + new Date());
      let endpoint = "";

      let filter = "?_sort=end:desc";

      if (this.checkupType != "all") {
        // endpoint = "/" + this.checkupType;
        filter += "&own=true"
      }

      let search = "";

      if (this.date) {
        filter = "&enddate=" + moment(this.date).toISOString();
      }

      if (this.search.length > 0) {
        search = (filter ? "&" : "?") + "checkup.title_contains=" + this.search;
      }

      this.request = "/checkupentries/computed" + endpoint + filter + search;

      let countRequest = await this.axios.get(
        "/checkupentries/computed" +
          /*(this.checkupType != "all" ? this.checkupType : "") +*/
          /*"count" +*/
          filter +
          search
      );
      this.totalCount = countRequest.data.length;
    },
    async getActions(teamid, refresh = false) {
      this.actionRequest =
        /*let request = await this.axios.get(*/ "/actions/team?teamid=" +
        teamid +
        "&_sort=created_at:desc" /*)*/;
      this.actionTeam = teamid;
      let countRequest = await this.axios.get(
        "/actions/team/count?teamid=" + teamid
      );
      if (refresh) {
        this.actionCount = 0;
      }
      this.actionCount = countRequest.data;

      // Get Messages
      this.messageRequest =
        "/messages?teamid=" + teamid + "&_sort=created_at:desc";
      countRequest = await this.axios.get("/messages/count?teamid=" + teamid);

      if (refresh) {
        this.messageCount = 0;
      }
      this.messageCount = countRequest.data;
    },
    async getCompanyActions(checkup, refresh = false) {
      this.companyActionRequest = "/actions/company?_sort=created_at:desc";
      this.companyMessageRequest = "/messages?checkupentry=" + checkup.id;
      let { data: count } = await this.axios.get("/actions/company/count");
      let { data: messageCount } = await this.axios.get(
        "/messages/count?checkupentry=" + checkup.id
      );
      if (refresh) {
        this.companyActionCount = 0;
        this.companyMessageCount = 0;
      }
      this.companyActionCount = count;
      this.companyMessageCount = messageCount;
    },
    showMessagePopup(message) {
      this.$store.dispatch("openModal", {
        component: MessageModal,
        options: { clickOutside: true },
        properties: { message: message },
      });
    },
    async handleModalSubmit() {
      await this.getActions(this.activeCheckups[0].swarm);
      await this.getCompanyActions(this.activeCheckups[0].checkupentry);
    },
    openActionModal(id) {
      this.$store.dispatch("openModal", {
        component: ActionModal,
        properties: {
          id: id,
        },
        options: {
          centered: true,
        },
        events: {
          submitModal: this.handleModalSubmit,
        },
      });
    },
    async updateAction(e, id) {
      await this.axios.put("/actions/" + id, {
        completed: e.target.checked,
      });
      await this.getActions(this.activeCheckups[0].swarm);
    },
  },
  created() {
    this.getCheckups();
    this.checkupsLoaded = true;
    this.contentReady = true;
    this.mitt.emit("loadcomplete", { complete: true });
  },
  data() {
    return {
      checkupsLoaded: false,
      moment: moment,
      checkupType: "own",
      totalCount: 0,
      date: "",
      search: "",
      tags: [],
      request: "/swarmcomputeds/own",
      actionRequest: "/actions/team?teamid=0",
      companyActionRequest: "/actions/company",
      actionCount: "",
      companyActionCount: "",
      actionTeam: "",
      messageRequest: "/messages?teamid=0",
      companyMessageRequest: "/messages?checkupentry=0",
      messageCount: "",
      companyMessageCount: "",
      messageContent: "",
      shownForm: "actions",
      actions: [],
      messages: [],
      checkups: [],
      activeList: null,
      activeSearch: "",
      contentReady: false,
      list_type: "own",
    };
  },
};
</script>

<style scoped lang="scss">
.checkup-card {
  border-radius: 8px !important;

  .card {
    cursor: pointer;
  }

  .card-header {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
}

.checkup-subgroup {
  @media all and (min-width: 768px) {
    max-height: 20vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.checkups-page-cards {
  @media all and (min-width: 768px) {
    height: 100%;
  }
}
</style>