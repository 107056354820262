<template>
  <div class="chartwrapper line">
    <canvas></canvas>
  </div>
</template>
<script>
import Chart from "chart.js/auto";

export default {
  mounted() {
    this.setData();
    this.setOptions();
    this.setConfig();
    this.initChart();
  },
  data() {
    return {
      chart: null,
      config: null,
      data: null,
      options: null,
    };
  },
  props: ["labels", "datasets"],
  watch: {
    datasets() {
      this.config.data.labels = this.labels;
      this.config.data.datasets = this.datasets;
      this.initChart();
    },
  },
  methods: {
    setData() {
      this.data = {
        labels: this.labels,
        datasets: this.datasets,
      };
    },
    setOptions() {
      this.options = {
        animation: {
          duration: 0,
        },
        scales: {
          y: {
            max: 100,
            min: 0,
            // ticks: {
            //   stepSize: 0.5
            // }
          },
        },
      };
    },
    setConfig() {
      this.config = {
        type: "line",
        data: this.data,
        options: this.options,
      };
    },
    initChart() {
      if (this.chart && this.chart.destroy) {
        this.chart.destroy();
      }
      this.chart = new Chart(this.$el.querySelector("canvas"), this.config);
    },
  },
};
</script>